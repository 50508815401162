<template>
  <a-spin class="max-width" :spinning="spinning" size="large" tip="加载中">
    <a-row type="flex" style="margin: 20px auto; max-width: 1600px;">
      <a-breadcrumb>
        <a-breadcrumb-item>
          <router-link :to="'/'">
            <a-icon type="home" />首页
          </router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-item>购物车</a-breadcrumb-item>
      </a-breadcrumb>
    </a-row>
    <!-- 产品列表 -->
    <a-table
      :columns="[
        { title: '产品', dataIndex: 'url', scopedSlots: { customRender: 'url' }, width: 110 },
        { title: '名称', dataIndex: 'name', scopedSlots: { customRender: 'name' } },
        { title: '单位', dataIndex: 'product_unit' },
        { title: '小计', dataIndex: 'order_money' },
        { title: '数量', dataIndex: 'amount', scopedSlots: { customRender: 'amount' }  },
        { title: '操作', dataIndex: 'action', scopedSlots: { customRender: 'action' } }
      ]"
      :row-key="record => record.id_order_cart"
      :data-source="data"
      :row-selection="rowSelection"
      :pagination="false"
    >
      <span slot="url" slot-scope="text">
        <div class="image">
          <img mode="aspectFix" v-lazy="text" />
        </div>
      </span>
      <span slot="name" slot-scope="text, record">
        <router-link :to="{ name: 'product', query: { id: record.id_product_info }}">
          <a class="theme" style="text-decoration: underline;">{{ text }}</a>
        </router-link>
        <br />
        <span>￥{{ record.price_settle }}</span>
      </span>
      <span slot="amount" slot-scope="text, record">
        <a-input-number
          @change="onModifyAmount(record)"
          v-model="record.amount"
          :min="record.num_minimum"
          :step="record.num_boxing"
        />
      </span>
      <span slot="action" slot-scope="text, record">
        <a-popconfirm
          title="您确定要移除该产品吗？"
          okText="确定"
          okType="danger"
          cancelText="取消"
          @confirm="onDelete(record.id_order_cart)"
        >
          <a-button class="red" type="link" style="padding: 0">
            <a-icon type="delete" />移除
          </a-button>
        </a-popconfirm>
      </span>
    </a-table>
    <!-- 产品合计 -->
    <div class="buy">
      <span class="grey" style="line-height: 50px; margin: 0 20px">
        已选
        <span class="red">{{ selectedDataKey.length }}</span>
        件产品，共
        <span class="red">{{ data.length }}</span>
        件产品
      </span>
      <a-row type="flex">
        <div class="red" style="line-height: 50px;">
          合计 ￥
          <span style="font-size:27px" class="bold">{{ totalPrice.toFixed(2) }}</span>
        </div>
        <div class="button" @click="onBuy">订单结算</div>
      </a-row>
    </div>
  </a-spin>
</template>
<script>
export default {
  data() {
    return {
      data: [], // 所有产品
      selectedData: [], // 选中的产品
      selectedDataKey: [], // 选中的产品id
      totalPrice: 0, // 总价
      spinning: true,
      rowSelection: {
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedDataKey = selectedRowKeys // 存产品 id
          this.selectedData = selectedRows // 存产品
          this.updatePrice()
        }
      }
    }
  },

  mounted() {
    this.fetchData()
  },

  methods: {
    fetchData() {
      this.spinning = true
      this.get('getAllCartForFranchisee', {
        page: 0,
        status: [1]
      })
        .then((res) => {
          this.data = res
          this.selectedData.map((e) => {
            e.order_money = res.find(
              (data) => data.id_order_cart === e.id_order_cart
            ).order_money
          })
          this.updatePrice()
          this.spinning = false
        })
        .catch(() => {
          // this.$message.error(err.msg)
          this.spinning = false
        })
    },
    updatePrice() {
      this.totalPrice = this.selectedData
        .map((record) => parseFloat(record.order_money))
        .reduce((prev, cur) => prev + cur, 0) // 求和
    },
    onBuy() {
      if (this.selectedDataKey.length === 0) {
        this.$message.warn('您还未选择产品')
        return false
      } else {
        this.setSession('orderMoney', this.totalPrice)
        this.setSession('orderKey', this.selectedDataKey)
        this.$router.push({ name: 'confirm' })
      }
    },
    onDelete(id) {
      this.spinning = true

      this.post('deleteCartForFranchisee', {
        id_order_cart: id
      })
        .then((res) => {
          // this.updatePrice()
          this.selectedData.forEach((e, index) => {
            if (e.id_order_cart === id) {
              this.selectedData.splice(index, 1)
              this.selectedDataKey.splice(index, 1)
            }
          })
          this.fetchData()
          this.spinning = false
        })
        .catch((err) => {
          this.$message.error(err.msg)
          this.spinning = false
        })
    },
    onModifyAmount(record) {
      this.spinning = true
      this.post('modifyCartForFranchisee', {
        id_order_cart: record.id_order_cart,
        amount: record.amount
      })
        .then(() => {
          this.fetchData()
        })
        .catch((err) => {
          this.$message.error(err.msg)
          this.data = []
          this.fetchData()
        })
    }
  }
}
</script>

<style scoped>
.max-width {
  min-height: 650px;
  max-width: 1600px;
  padding: 0 50px;
}
.image {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.image img {
  max-width: 100%;
  max-height: 60px;
}
.buy {
  width: 100%;
  height: 50px;
  border-bottom: 1px solid #e7e7e7;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.buy .button {
  text-align: center;
  background: var(--theme);
  color: #fff;
  width: 200px;
  height: 50px;
  margin-left: 30px;
  line-height: 50px;
  font-size: 22px;
  cursor: pointer;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.buy .button:hover {
  transform: scale(1.03);
}
</style>
